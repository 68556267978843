<template>
  <div class="home">
    <HelloWorld
      title="O autorze"
      :gal_id=0
    >
    </HelloWorld>
    <Page
      title="Akty"
      img_source="https://amkrawczyk.pl/amkrawczyk.pl_ryciny/akty/21 a.jpg"
      :gal_id=1
    >
    </Page>
    <Page
      title="Architektura"
      img_source="https://amkrawczyk.pl/amkrawczyk.pl_ryciny/architektura/01_0_0.jpg"
      :gal_id="2"
    ></Page>
    <Page
      title="Caballus"
      img_source="https://amkrawczyk.pl/amkrawczyk.pl_ryciny/caballus/06_2.jpg"
      :gal_id="3"
    ></Page>
    <Page
      title="Fantasmagoria"
      img_source="https://amkrawczyk.pl/amkrawczyk.pl_ryciny/ja/01_0_15097f68253916.jpg"
      :gal_id="4"
    ></Page>
    <Page
      title="Koty"
      img_source="https://amkrawczyk.pl/amkrawczyk.pl_ryciny/koty/2011-01-19@20220.jpg"
      :gal_id="5"
    ></Page>
    <Page
      title="Kwiaty"
      img_source="https://amkrawczyk.pl/amkrawczyk.pl_ryciny/kwiaty/08.JPG"
      :gal_id="6"
    ></Page>
    <Page
      title="Martwa Natura"
      img_source="https://amkrawczyk.pl/amkrawczyk.pl_ryciny/martwa natura/ak 062.jpg"
      :gal_id="7"
    ></Page>
    <Page
      title="Pejzaż"
      img_source="https://amkrawczyk.pl/amkrawczyk.pl_ryciny/pejzaz/02.jpg"
      :gal_id="8"
    ></Page>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import Page from "@/components/Page.vue";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
    Page,
  },
};
</script>
