<template>
  <div id="container">
    <!-- <a title="RSS 2.0 Feed" href="feed/index.html" id="rssfeed">RSS Feed</a> -->
    <div id="header" class="clearfix">
      <h1><a href="/">amkrawczyk.pl</a> <span></span></h1>
      <ul id="nav">
        <li><router-link class="nav-link" to="/"> Home </router-link></li>
        <li><router-link to="akty"> Akty </router-link></li>
        <li><router-link to="architektura"> Architektura </router-link></li>
        <li><router-link to="caballus"> Caballus </router-link></li>
        <li><router-link to="fantasmagoria"> Fantasmagoria </router-link></li>
        <li><router-link to="koty"> Koty </router-link></li>
        <li><router-link to="kwiaty"> Kwiaty </router-link></li>
        <li><router-link to="martwa-natura"> Martwa Natura </router-link></li>
        <li><router-link to="pejzaz"> Pejzaż </router-link></li>
        <li class="last">
          <form method="get" action="https://amkrawczyk.pl/">
            <fieldset>
              <!-- <input type="text" name="s" id="s" value="" />
              <input type="submit" value="Search" name="submit" id="submit" /> -->
            </fieldset>
          </form>
        </li>
      </ul>
    </div>

    <div id="main" class="clearfix">
      <div id="content">
        <div class="inner">
          <router-view></router-view>
          <div class="clearboth"></div>
          <div class="navigation">
            <div class="alignleft"></div>
            <div class="alignright"></div>
          </div>
        </div>
      </div>

      <div id="sidebar">
        <div class="main">
          <div class="middle">
            <div class="bottom">
              <h2>Komentarze</h2>
              <ul id="recentcomments">
                <ul v-for="comment in postId.data" class="commentlist" :key="comment.id">
                  <li class="recentcomments">
                    <span class="comment-author-link">{{ comment.NAME }} - {{galeries[ comment.GAL_ID ].name}}  </span>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="clearboth"></div>
    </div>

    <hr />
    <div id="footer" role="contentinfo">
      <!-- If you'd like to support WordPress, having the "powered by" link somewhere on your blog is the best way; it's our only promotion or advertising. -->
      <p>
        <!-- <component_a></component_a> -->
      </p>
    </div>
  </div>
</template>
<script>
// var postId = null;
var gal_name = null;
import axios from "axios";
export default {
  data() {
    return {
      galeries: [
                { id: 0, name: "O autorze" },   
                { id: 1, name: "Akty" },          
                { id: 2, name: "Architektura" },
                { id: 3, name: "Caballus" },
                { id: 4, name: "Fantasmagoria" },
                { id: 5, name: "Koty" },
                { id: 6, name: "Kwiaty" },
                { id: 7, name: "Martwa Natura" },
                { id: 8, name: "Pejzaż" },           
              ],       
      gal_name: [{ name: "O autorze" }, { name: "Akty" }],
      count: 777,
      postId: 0,
    };//end return
  },
  created() {
    // axios
    //   .post("https://amkrawczyk.pl/fetchdata/", {
    //     // gal_id: -1,
    //     action: "fetchAll",
    //   })
    //   .then((response) => {
    //     this.postId = response.data;
    //     this.count = response.data.data.length;
    //     // console.log(response.data.data);
    //     console.log(this.gal_name[0].name)
    //   });
    this.fetchCommentsData();
  },
  methods:{
    fetchCommentsData(gal_id_param){
      axios
      .post("https://amkrawczyk.pl/fetchdata/", {
        gal_id: gal_id_param,
        action: "fetchAll",
      })
      .then((response) => {
        this.postId = response.data;
        this.count = response.data.data.length;
        console.log(response.data.data.length);
      });
    }
  }
};
</script>
<style>
@import "./assets/style.css";

/* #header ul li a {
  display: block;
  font: 15px "Times New Roman", Times, serif;
  padding: 15px 7px;
  color: #BF9B8F;
  text-decoration: none;
  background: none;
  border-bottom: 1px solid #7D160E;
} */
</style>
