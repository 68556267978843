<template>
  <div
    id="post-496"
    class="mainbg post-496 post type-post status-publish format-standard sticky hentry category-bez-kategorii"
  >
    <div class="calendar"><small>lis</small> <strong>5</strong></div>
    <div
      v-if="commentsVisible"
      exact
      :style="{ cursor: 'pointer' }"
      @click="Visible"
      class="comments"
    >
      <a>{{ this.count }}</a>
    </div>
    <h1 class="post">
      <a rel="bookmark" title="Permanent Link to O autorze">{{ title }}</a>
    </h1>
    <!-- <Adsense data-ad-client="ca-pub-4581833054780437" data-ad-slot="0852194655">
    </Adsense> -->
    <div class="bottombg">
      <div class="buffer">
        <div class="posted">
          Posted on <span>poniedziałek, 55 listopada, 2012</span>
        </div>
        <p>
          <strong>Andrzej M. Krawczyk</strong><br />
          plastyk
        </p>
        <p>
          e-mail:<br /><a href="mailto:andrzej.m.krawczyk@o2.pl"
            >andrzej.m.krawczyk@o2.pl</a
          >
        </p>
        <div id="mjyymzmyntu4oq">
          <div></div>
        </div>
        <p>
          nr tel: <em><strong>885 641 181</strong></em>
        </p>
        <p><sub>ryciny pastelowe</sub></p>
        <div class="postfooter"></div>
      </div>
    </div>
    <Comments v-if="!commentsVisible" :gal_id="0"></Comments>
  </div>
</template>

<script>
// import axios from 'axios';
// import Page from "./Page.vue";
import Comments from "./Comments.vue";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";
import axios from "axios";
export default {
  name: "O Autorze",
  components: {
    Viewer,
    Comments,
  },
  props: {
    title: null,
  },
  data() {
    return {
      commentsVisible: true,
      title: this.title,
      postId: null,
      count: null,
    };
  },
  created() {
    axios
      .post("https://amkrawczyk.pl/fetchdata/", {
        gal_id: 0,
        action: "fetchAll",
      })
      .then((response) => {
        this.postId = response.data;
        this.count = response.data.data.length;
      });
  },
  methods: {
    Visible() {
      // console.log("visible Hello World");
      this.commentsVisible = false;
    },
  },
};
</script>
