import { createApp } from 'vue'
import App from './App.vue'
// import VueViewer from 'vue-viewer';
import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';
import ScriptX from 'vue-scriptx'
import router from './router'
import store from './store'
import Ads from 'vue-google-adsense'

createApp(App).use(Ads.Adsense).use(Ads.InArticleAdsense).use(Ads.InFeedAdsense).use(ScriptX).use(store).use(router).use.apply(VueViewer).mount('#app')
