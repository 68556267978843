<template>
  <div id="comments" class="clearfix">
    <div class="navigation">
      <div class="alignleft">
        <!-- « <a href="../koty/index.html" rel="prev">Koty</a>--------------------------------------- -->
      </div>
      <div class="alignright">
        <!-- <a href="../martwa-natura/index.html" rel="next">Martwa Natura</a> »--------------------- -->
      </div>
    </div>
    <h2>Przynieś na komentarze</h2>

    <ol v-for="comment in postId.data" class="commentlist" :key="comment.id">
      <li class="comment even thread-even depth-1" id="comment-24">
        <div id="div-comment-24" class="comment-body">
          <div class="comment-author vcard">
            <cite class="fn">{{ comment.NAME }}</cite>
            <span  class="says"> pisze: <div style="display:block; word-wrap:break-word;">{{ comment.COMMENT_TEXT }} </div></span>
            <!-- <li  >
              <div>Comment ID:{{ comment.ID }} GalID: {{comment.GAL_ID}} DATA: {{comment.DATA}} Comment: {{comment.COMMENT_TEXT}}<br><br></div>
            </li> -->
          </div>

          <div class="comment-meta commentmetadata">
            <a></a>
          </div>
          <div>
            <div class="buffer">
              <!-- style="display: none;" -->
              <!-- ----------------------------------------------------------------- -->
              <div class="posted">
                Komentarz dnia <span>{{ comment.DATA }}</span>
              </div>

              <!-- <p>Host: {{ host }} {{ id }} DATA {{ date }}</p> -->
              <div id="app">
                <small>{{ captchaCodeVar }}</small>
              </div>

              <!-- <div class="postfooter"></div> -->
            </div>
          </div>

          <!-- <p>Cudownie oddana struktura bzu w 11{{ count }}</p> -->
        </div>
      </li>
      <!-- #comment-## -->
    </ol>
    <!-- ---------------------------------------------------------------------------- -->
    <form onsubmit="return false" :class="this.errors ? 'errors' : false">
      <label for="name">Name:</label>
      <input
        id="name"
        placeholder="Podaj swoje imię."
        v-model="formData.name"
        required
        @invalid="invalidateForm"
      />

      <label>Email</label>
      <input
        type="email"
        placeholder="Podaj swój adres e-mail."
        v-model="formData.email"
      />

      <label>Treść komentarza:</label>
      <textarea
        id="w3review"
        name="w3review"
        rows="4"
        cols="50"
        type="text"
        v-model="formData.comment_text"
      ></textarea>
      <VueClientRecaptcha @getCode="getCaptchaCode" />
      <br />
      <input v-model="inputCode" placeholder="Podaj kod z obrazka." />
      <br /><br /><br />
      <button @click="sendData">Zatwierdź :)</button>
    </form>
    <small>{{ captchaCodeVar }}</small>
    <!-- ------------------------------------------- -->
    <div class="navigation">
      <div class="alignleft"></div>
      <div class="alignright"></div>
    </div>

    <div id="respond">
      <!-- <h3>Zostaw Odpowiedz</h3> -->

      <fieldset id="cancel-comment-reply">
        <small
          ><a
            rel="nofollow"
            id="cancel-comment-reply-link"
            href="index.html#respond"
            style="display: none"
            >Kliknij tutaj, aby anulować odpowiadanie.</a
          ></small
        >
      </fieldset>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueClientRecaptcha from 'vue-client-recaptcha'
export default {
  props: {
    gal_id: Number,
  },
  data() {
    return {
      postId: 0,
      count: null,
      formData: {
        action: "addComment",
        name: "Nazwa",
        email: "twoj.adres@email.pl",
        comment_text: "Twój komentarz",
        gal_id: this.gal_id,
      },
    };
  },
  created() {
    axios
      .post("https://amkrawczyk.pl/fetchdata/", {
        gal_id: this.gal_id,
        action: "fetchAll",
      })
      .then((response) => {
        this.postId = response.data;
        this.count = response.data.data.length;
      });
  },
  methods: {
    invalidateForm() {
      this.errors = true;
    },
    getCaptchaCode(value) {
      /* you can access captcha code */
      this.captchaCodeVar = value;
    },
    sendData() {
      // this.handleConfirm();
      if (this.captchaCodeVar === this.inputCode) {
     /* this.captchaCodeVar === this.inputCode) {*/
      axios
        .post("https://amkrawczyk.pl/fetchdata/", this.formData)
        .then((response) => {
          // Handle the response from the server
          this.count = response.data.data.length;
          // console.log(response.data);
          location.reload();
        })
        .catch((error) => {
          // Handle any errors
          console.error(error);
        });
      // this.$refs.captcha.refreshCaptcha();
      this.inputCode = "";
      } else {
        alert("Nie prawidłowy kod z obrazka!!!");
      }
    },
  },
  components:{
    VueClientRecaptcha
  }
};
</script>

<style></style>
