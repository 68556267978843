<template>
    <div id="post-92" class="mainbg post-92 post type-post status-publish format-standard hentry category-bez-kategorii">
        <div class="calendar"><small>lis</small> <strong>4</strong></div>
        <div exact :style="{ cursor: 'pointer'}" @click="Visible"  v-if="commentsVisible"  class="comments"><a   >{{ this.count }}</a>
        </div>
        
        <h1 class="post"><a href="architektura/index.html" rel="bookmark" title="Permanent Link to Architektura">{{ title
        }}</a></h1>
        <div class="bottombg">
            <div class="buffer">
                <div class="posted">Posted on <span>niedziela, 4 listopada, 2012</span> in <span><a
                             rel="category tag"></a></span></div>
                <p>
                    <div class="gallery_box">
                        <ul>
                            <li>
                                <!-- ---------------------------------------------------------------------------------------------------- -->
                                <a @click="startGallery( gal_id )" rel="bookmark"  >
                                    <img width="120" height="80"
                                        style="width:120px; height:80px; border-width: 10px; border-color:#F1F1F1;border: 10px solid #F1F1F1"
                                        alt="Architektura" :title="title" :src="img_source" /> </a>
                                <div class="gallery_detail_box">
                                    <div class="gllr_detail_title">Architektura</div>
                                    <div class="gllr_detail_excerpt"></div>
                                    <a @click="startGallery( gal_id )">Zobacz zdjęcia &raquo;</a>
                                </div>
                                <div class="gllr_clear"></div>
                            </li>
                        </ul>
                    </div>
                </p>
                <div id="ca-pub-4581833054780437:0852194655" class="ads ads_after">
                    <!-- <script type="text/javascript">
                    google_ad_client = "ca-pub-4581833054780437";
                    google_ad_slot = "0852194655";
                    google_ad_width = 728;
                    google_ad_height = 90;
                </script><br /> -->
                    <br />
                    <!-- <script type="text/javascript" src="../pagead2.googlesyndication.com/pagead/f.txt"> -->
                    <!-- </script> -->
                </div>
                <div class="postfooter"> </div>
            </div>
        </div>
        <Comments v-if="!commentsVisible" :gal_id=this.gal_id></Comments>
    </div>
    
</template>
   
<script>
var gallery = [];
var Photo = [];


import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import axios from 'axios';
import Comments from './Comments.vue';
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from "v-viewer"

export default {
    name: 'Page',
    props: {
        title: String,
        img_source: String,
        gal_id: Number
    },
    components: {
        Viewer,
        Comments
    },
    data() {
        return {
            commentsVisible: true,
            Photo,
            postId: null,
            count: null,
            showDiv: false,
        };
    },
    mounted(){
            axios.post("https://amkrawczyk.pl/fetchdata/", {
                gal_id: this.gal_id,
                action:'fetchAll' })
                .then((response) => {
                this.count = response.data.data.length;
                Photo[this.gal_id] = response.data.list;
                //  console.log(Photo[this.gal_id]);
            });
        },
    initialize() {
     
    },    
    methods: {
        Visible(){
            this.commentsVisible = false;
        },
        startGallery: ( gal_id ) => {
                Photo [ gal_id ].forEach((element) => {
                gallery.push({
                src: "https://amkrawczyk.pl/amkrawczyk.pl_ryciny/" + element,
                thumb: "https://amkrawczyk.pl/amkrawczyk.pl_ryciny/" + element,
                });
            });
            Fancybox.show(gallery, {}); //starts fancybox with the gallery object
            gallery = [];
        },
    
    }
}

</script>